import { css } from 'styled-components';

export const theme = {
  primary: 'hsl(49, 100%, 58%)',
  white: 'hsl(0, 0%, 100%)',
  grey: 'hsl(0, 0%, 97%)',
  darkGrey: 'hsl(0, 0%, 77%)',
  black: 'hsl(0, 0%, 0%)',
  font: {
    size: {
      xxxs: '.9rem',
      xxs: '1.2rem',
      xs: '1.4rem',
      s: '2.6rem',
      m: '4.2rem',
      l: '5rem',
      xl: '7.2rem',
      xxl: '9.4rem',
    },
    family: {
      neuville: '"Neuville", sans-serif',
      raleway: '"Raleway", sans-serif',
    },
  },
  mq: {
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    bigDesktop: `@media (min-width: 1280px)`,
    huge: `@media(min-width: 1440px)`,
  },
  addBackground: (height, width, top, left, color = theme.primary) =>
    css`
      &::before {
        z-index: -1;
        content: '';
        background-color: ${color};
        height: ${height};
        width: ${width};
        top: ${top};
        left: ${left};
        position: absolute;
      }
    `,
};
