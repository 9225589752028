import React from 'react';
import { Link } from 'gatsby';
import iconYoutube from 'assets/images/icon_youtube.svg';
import iconFacebook from 'assets/images/icon_facebook.svg';
import iconInstagram from 'assets/images/icon_instagram.svg';
import footerLogoMobile from 'assets/images/logo_footer_mobile.svg';
import footerLogoDesktop from 'assets/images/logo_footer_desktop.svg';
import trianglesImage from 'assets/images/pattern_triangles.svg';
import {
  Wrapper,
  StyledIcon,
  MobileLogoIcon,
  DesktopLogoIcon,
  SocialIcons,
  StyledParagraph,
  TrianglesImage,
} from './Footer.styles';

const Footer = () => (
  <Wrapper>
    <TrianglesImage src={trianglesImage} />
    <MobileLogoIcon src={footerLogoMobile} />
    <DesktopLogoIcon src={footerLogoDesktop} />
    <SocialIcons>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://youtube.com/helloroman"
      >
        <StyledIcon src={iconYoutube} />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://facebook.com/helloroman.vlog"
      >
        <StyledIcon src={iconFacebook} />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://instagram.com/siemaroman"
      >
        <StyledIcon src={iconInstagram} />
      </a>
    </SocialIcons>
    <StyledParagraph
      style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
      as={Link}
      to="/polityka-prywatnosci"
    >
      Polityka prywatności
    </StyledParagraph>
    <StyledParagraph bold>
      © {new Date().getFullYear()} Adam Romański. All rights reserved
    </StyledParagraph>
  </Wrapper>
);

export default Footer;
