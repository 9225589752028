import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  flex-shrink: 0;
  text-decoration: none;
  padding: 10px 25px;
  border: none;
  font-size: ${({ theme, small }) =>
    small ? theme.font.size.xxs : theme.font.size.xs};
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  position: relative;
  background: none;
  z-index: 1;
  cursor: pointer;

  ::after {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.black};
    opacity: 1;
    transition: transform 0.15s ease-out;
  }

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5px;
    right: 5px;
    background-color: ${({ theme, isWhite }) =>
      isWhite ? 'white' : theme.primary};
    z-index: -1;
    transition: transform 0.15s ease-out;
  }

  &:disabled::before {
    background-color: ${({ theme }) => theme.darkGrey} !important;
  }

  :hover::before {
    transform: translate(5px, -5px);
  }

  :hover::after {
    transform: translate(-5px, 5px);
  }
`;

export default Button;
