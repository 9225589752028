import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import iconYoutube from 'assets/images/icon_youtube.svg';
import iconFacebook from 'assets/images/icon_facebook.svg';
import iconInstagram from 'assets/images/icon_instagram.svg';

const Wrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
    flex-direction: row;
    position: static;
    width: unset;
  }
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 29px;
  height: 15px;
  width: auto;

  svg {
    height: 100%;
    width: auto;
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 0 40px 0 0;
  }
`;

const SocialMenu = ({ isOnFaq }) => (
  <Wrapper isMobileHidden={isOnFaq}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://youtube.com/helloroman"
    >
      <StyledIcon src={iconYoutube} />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://facebook.com/helloroman.vlog"
    >
      <StyledIcon src={iconFacebook} />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://instagram.com/siemaroman"
    >
      <StyledIcon src={iconInstagram} />
    </a>
  </Wrapper>
);

SocialMenu.propTypes = {
  isOnFaq: PropTypes.bool,
};

SocialMenu.defaultProps = {
  isOnFaq: false,
};

export default SocialMenu;
