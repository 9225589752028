import styled from 'styled-components';
import { Paragraph } from 'components/Typo';
import Icon from 'components/Icon/Icon';

export const TrianglesImage = styled.img`
  display: none;
  object-fit: contain;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px;
  position: relative;

  ${({ theme }) => theme.mq.desktop} {
    align-items: center;
    min-height: 230px;
    justify-content: space-between;
  }
`;

export const MobileLogoIcon = styled(Icon)`
  width: 100px;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

export const DesktopLogoIcon = styled(Icon)`
  width: 140px;
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

export const StyledIcon = styled(Icon)`
  margin: 0 25px 0 0;
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 0 10px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.xxs};
`;

export const SocialIcons = styled.div`
  display: flex;
  margin: 15px 0;

  ${({ theme }) => theme.mq.desktop} {
    justify-content: center;
  }
`;
