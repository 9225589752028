import styled from 'styled-components';

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.size.m};
  align-self: flex-start;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mq.desktop} {
    line-height: 1;
    font-size: ${({ theme }) => theme.font.size.xl};
    width: 300px;
    grid-column: 1;
    grid-row: 1;
    top: -20%;
  }
`;
