import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Logo from 'components/Logo/Logo';
import SocialMenu from 'components/SocialMenu/SocialMenu';
import Button from 'components/Button/Button';

const Wrapper = styled.nav`
  padding: 36px 34px;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 9999;

  ${({ theme }) => theme.mq.desktop} {
    align-items: center;
  }
`;

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 9999;
  width: 90px;

  svg {
    .face {
      fill: ${({ theme }) => theme.white};
    }
  }

  ${({ theme }) => theme.mq.desktop} {
    width: 150px;

    svg {
      .face {
        fill: ${({ theme }) => theme.primary};
      }
    }
  }
`;

const FaqButton = styled(Button)`
  display: none;
  font-size: ${({ theme }) => theme.font.size.xxs};
  margin-left: 20px;

  ${({ theme }) => theme.mq.desktop} {
    display: inline-block;
  }
`;

const MobileFaqButton = styled(({ isOnFaq, ...props }) => <Button {...props} />)`
  position: relative;
  top: -5px;
  right: -5px;
  margin-left: 15px;
  display: inline-block;
  visibility: ${({ isOnFaq }) => (isOnFaq ? 'hidden' : 'visible')};
  font-size: ${({ theme }) => theme.font.size.xxs};

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 30px;
  top: -100%;

  ${({ theme }) => theme.mq.desktop} {
    position: static;
    align-items: center;
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <Link to="/">
        <StyledLogo />
      </Link>
      <MobileFaqButton isWhite onClick={() => navigate('/faq')}>
        faq
      </MobileFaqButton>
      <ButtonWrapper>
        <SocialMenu />
        <FaqButton isWhite onClick={() => navigate('/faq')}>
          faq
        </FaqButton>
        <FaqButton isWhite onClick={() => navigate('https://sklep.helloroman.pl')}>
          sklep
        </FaqButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Header;
